.lightbox-modal.ant-modal{
    max-width: 1134px;
}

.lightbox-modal.ant-modal .ant-modal-content{
background-color: transparent;
box-shadow: none;
padding: 0;
}
.collapse-categories-results .ant-collapse-item{
    position: relative;
}
.collapse-categories-results .header-panel {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
}

.collapse-categories-results .header-panel > .ant-collapse-header {
    position: relative;
    padding-left: 12px; /* Espaço para a faixa */
    display: flex;
    align-items: center;
}

.collapse-categories-results .ant-collapse-item::before {
    content: "";
    position: absolute;
    width: 7px;
    background-color: var(--panel-color);
    transition: height 0.3s ease-in-out;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}

.collapse-categories-results .header-panel-active::before {
height: 100%;
}

.collapse-categories-results .header-panel[data-color="Optimal Mobility"]::before {
    background-color: #52c41a;
  }
  
  .collapse-categories-results .header-panel[data-color="Functional Mobility"]::before {
    background-color: #52c41a;
  }
  
  .collapse-categories-results .header-panel[data-color="Moderate Restriction"]::before,
  .collapse-categories-results .header-panel[data-color="Limited Mobility"]::before {
    background-color: #faad14;
  }
  
  .collapse-categories-results .header-panel[data-color="Restricted Mobility"]::before {
    background-color: #d9363e;
  }
  
  .collapse-categories-results .header-panel[data-color="Critically Impaired"]::before {
    background-color: #d9363e;
  }
  .report-checkbox .ant-checkbox-inner{
    border: 1px solid #d9d9d9!important;
  }