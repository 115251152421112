.lightbox-modal.ant-modal {
	max-width: 1134px;
}
.collapse-categories-result {
	overflow: hidden;
}

.lightbox-modal.ant-modal .ant-modal-content {
	background-color: transparent;
	box-shadow: none;
	padding: 0;
}

.collapse-categories-result .header-panel {
	border: 1px solid #d9d9d9;
	border-radius: 8px !important;
	overflow: hidden;
}

.collapse-categories-result .header-panel > .ant-collapse-header {
	position: relative;
	padding-left: 12px; /* Espaço para a faixa */
	display: flex;
	align-items: center;
}

.collapse-categories-result .ant-collapse-item::before {
	content: '';
	position: absolute;
	width: 7px;
	background-color: var(--panel-color);
	transition: height 0.3s ease-in-out;
	height: 100%;
	border-radius: 0.5rem 0 0 0.5rem;
}

.collapse-categories-result .header-panel-active::before {
	height: 100%;
}

.collapse-categories-result
	.header-panel[data-color='Optimal Mobility']::before {
	background-color: #52c41a;
}

.collapse-categories-result
	.header-panel[data-color='Functional Mobility']::before {
	background-color: #52c41a;
}

.collapse-categories-result
	.header-panel[data-color='Moderate Restriction']::before {
	background-color: #faad14;
}

.collapse-categories-result
	.header-panel[data-color='Restricted Mobility']::before {
	background-color: #d9363e;
}

.collapse-categories-result
	.header-panel[data-color='Severely Restricted']::before {
	background-color: #d9363e;
}

.collapse-categories-result
	.header-panel[data-color='Critically Impaired']::before {
	background-color: #d9363e;
}
.report-checkbox .ant-checkbox-inner {
	border: 1px solid #d9d9d9 !important;
}
.body-hotspots {
	padding: '24px';
	position: 'relative';
}
.body-hotspots .ant-card-body {
	text-align: center;
}

.rounded-table-left .ant-table-content {
	border-radius: 12px 0 0 12px;
}

.rounded-table-left .ant-table {
	border-radius: 12px 0 0 12px;
}
.rounded-table-left .ant-table-thead > tr > th:last-child {
	border-top: 1px solid #f0f0f0;
	border-start-end-radius: 0 !important;
}

.rounded-table-right .ant-table {
	border-radius: 0 12px 12px 0;
}
.rounded-table-right .ant-table-thead > tr > th {
	border-top: 1px solid #f0f0f0;
	border-start-start-radius: 0 !important;
}

.radar-chart .ant-card-body {
	height: 100%;
}
.rounded-table-left .ant-table-wrapper {
	border-right: 1px solid #f0f0f0;
}
.rounded-table-right .ant-table-wrapper {
	border-left: 1px solid #f0f0f0;
}

.custom-header-align {
	text-align: center !important;
	width: 22%;
}
.custom-header-align-right {
	padding-left: 10px;
}

.rounded-table-left{
	border-right: 1px solid #EAECF0;
}
.rounded-table-right{
	border-left: 1px solid #EAECF0;
}
.rounded-table-left .ant-table-tbody > tr:nth-child(even),
.rounded-table-right .ant-table-tbody > tr:nth-child(even) {
	background-color: #f9f9f9;
}
.rounded-table-left .ant-table-tbody > tr:nth-child(odd),
.rounded-table-right .ant-table-tbody > tr:nth-child(odd) {
	background-color: #ffffff;
}

.progress-score {
	.ant-progress-text {
		font-weight: bold;
		color: #fff !important;
		padding: 8px;
		border-radius: 8px;
		font-size: 18px;
		width: 100%;
		background-color: var(--my-color);
	}
}
.progress-score::after {
	content: 'Average Score';
	font-size: 12px;
	position: relative;
	top: -22px;
	left: 5px;
	color: #999;
}
.rounded-table-right .ant-spin-nested-loading {
	width: 100%;
}
.progress-score.ant-progress .ant-progress-steps-outer {
	position: relative;
	top: 0;
	align-items: flex-start;
}
.progress-score.ant-progress .ant-progress-steps-item {
	position: relative;
	top: 6px;
}
