.charts .segment.tertiary {
	margin-bottom: 10px;
}

/* THIS CLASS IS TO TOOLTIP CHART */
.g2-tooltip {
	/* padding: 0 !important; */
	border-radius: 8px !important;
}

.tipChart {
	padding: 0;
	position: relative;
}

.tipChart .date,
.tipChart .value {
	position: absolute;
	color: #fff;
	padding: 5px;
	border-radius: 6px;
}

.tipChart .date {
	background: rgba(255, 255, 255, 0.8);
	color: var(--mainmenu-bg-color);
	top: 9px;
	left: 9px;
	font-size: 12px;
}

.screenshot.tipChart .date {
	right: 9px;
	left: auto;
}

.tipChart .value {
	background: var(--mainmenu-bg-color);
	bottom: 9px;
	right: 9px;
	font-size: 18px;
	font-weight: 700;
}

.screenshot.tipChart .value {
	bottom: 14px;
	right: 8px;
}

.tipChart .printscreen img {
	border-radius: 8px;
}

span.anticon.anticon-check-circle.select-icon {
	color: #159e4d;
	position: absolute;
	z-index: 1;
	top: 5px;
	left: 5px;
	font-size: 25px;
	cursor: pointer;
}

.screenshot.tipChart img {
	border-radius: 6px;
}

.screenshot.tipChart img.active {
	outline: 4px solid #01c752;
	padding: 0 !important;
	box-sizing: border-box;
}

.screenshot.tipChart:hover .select-icon.active {
	color: #01c752;
}

.screenshots-tab {
	padding: 0 5px;
}

.screenshot.tipChart .value,
.screenshot.tipChart .date,
.screenshot.tipChart .select-icon {
	display: none;
}

.screenshot.tipChart:hover .value,
.screenshot.tipChart:hover .date,
.screenshot.tipChart:hover .select-icon,
.screenshot.tipChart .select-icon.active {
	display: block;
}

.ant-image-mask {
	border-radius: 6px;
}

.btn-compare {
	border-radius: 25px;
	padding: 10px 40px;
	background: transparent;
	border: 2px solid #ffffff;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 18px;
	font-weight: 600;
}

button.btn-compare.active {
	background: #01c752;
	border-color: #18ff76;
}

button.btn-compare:disabled {
	opacity: 0.2;
}

.gallery-modal {
	width: 60% !important;
	height: auto !important;
}

.screen-gallery>div {
	height: auto !important;
	min-height: 610px !important;
}

.gallery-top {
	border: none !important;
	padding: 10px;
	text-align: center;
	position: fixed;
	bottom: 0;
	background: transparent;
	display: none;
	width: 100%;
	left: 0;
	z-index: 102;
}

.gallery-top.active {
	background: rgb(0 0 0 / 22%);
	display: block;
}

/* coach performance */
.custom-tooltip-title {
	margin: 0px 12px;
	padding: 8px 0 8px;
	font-size: 12px;
	border-bottom-style: solid;
	border-bottom-width: thin;
	border-bottom-color: #e9e9e9;
}

.custom-tooltip-value {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 8px 12px 0 12px;
	padding-bottom: 8px;
	font-size: 20px;
	text-align: center;
	border-bottom-style: solid;
	border-bottom-width: thin;
	border-bottom-color: #e9e9e9;
	height: 70px;
}

.custom-tooltip-temp {
	display: flex;
	position: relative;
	align-items: center;
}

.custom-tooltip-temp span:first-child {
	font-size: 12px;
	position: absolute;
	top: 0px;
	color: rgba(0, 0, 0, 0.45);
}

.custom-tooltip-temp span:last-child {
	text-align: left;
	margin-top: 10px;
	position: relative;
	color: rgba(0, 0, 0, 0.45);
}

.custom-tooltip-wind {
	margin: 8px 12px 12px 12px;
	font-size: 20px;
	color: rgba(0, 0, 0, 0.85);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.export-data {
	text-align: right;
}

.video-item .result {
	color: rgb(255, 255, 255);
	padding: 47px;
	width: 300px;
	background: #f0f2f5;
	height: 168.75px;
}

.video-item .result .ant-result-title {
	color: rgb(107 43 160);
	font-size: 15px;
	line-height: 1.8;
	text-align: center;
}

.video-item .result .ant-result-icon {
	margin-bottom: 0;
	text-align: center;
}

.exported-table {
	width: 100%;
	padding-top: 30px;
}

.exported-table tr,
.exported-table td {
	border: 1px solid;
}

.type-content label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
	border-color: transparent;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: rgb(94, 94, 94);
}

.btn-export-data {
	float: right;
}

.ant-radio-group.ant-radio-group-outline.type-content {
	margin-bottom: 15px;
}

.type-content .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: transparent;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #2b2b2b;
	background: #f0f0f0;
	border-color: #d9d9d9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.type-content label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
	border-color: #d9d9d9;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.type-content {
	color: #2b2b2b;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #d9d9d9 !important;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: none;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #d9d9d9;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #2b2b2b;
	border-color: #d9d9d9;
}

.type-content .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #ccc;
}

.action-menu button {
	border-radius: 6px;
}

.action-menu button:hover {
	background-color: #fafafa;
	color: #6739b7;
}

.action-menu button svg {
	font-size: 16px;
	vertical-align: text-top;
	margin-right: 3px;
}

.editable-row .ant-form-item-explain {
	position: absolute;
	top: 100%;
	font-size: 12px;
}

.ant-collapse-extra div {
	display: inline-block;
	padding-right: 9px;
}

.btn-start-exercise {
	font-size: 22px;
	vertical-align: middle;
	color: #6739b7;
}

.btn-start-exercise path {
	stroke: #6b2ba0;
}

.ant-collapse-header-text .ant-tag {
	margin-left: 12px;
}

.ant-card-small >.ant-card-body {
	padding: 0px;
}